export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en-US",
    "resource": {
      "all-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 Limitless Asset Management All Rights Reserved"])},
      "terms-of-use-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
      "privacy-policy-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
    }
  })
}
