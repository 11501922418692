export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en-US",
    "resource": {
      "balances-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])},
      "trades-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
      "deposits-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposits"])},
      "withdrawals-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawals"])},
      "users-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "create-account-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "change-password-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "log-out-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
    }
  })
}
