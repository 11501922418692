import { TokenResponse, UserResponse } from '@/api/apiTypes'

const TOKEN_KEY = 'token'

let cachedToken: TokenResponse | null
export function getToken(): TokenResponse | null {
  if (!cachedToken) {
    cachedToken = _parseStoredToken()
  }

  return cachedToken
}

export function getTokenOwner(): UserResponse | null {
  return getToken()?.owner || null
}

export function storeToken(_token: TokenResponse): void {
  cachedToken = _token
  localStorage.setItem(TOKEN_KEY, JSON.stringify(_token))
}

export function clearToken(): void {
  cachedToken = null
  localStorage.removeItem(TOKEN_KEY)
}

function _parseStoredToken(): TokenResponse | null {
  const tokenRaw = localStorage.getItem(TOKEN_KEY)
  if (!tokenRaw) return null

  let token: TokenResponse | null
  try {
    token = JSON.parse(tokenRaw)
  } catch (error) {
    return null
  }

  if (!_checkTokenValid(token)) {
    localStorage.removeItem(TOKEN_KEY)
    return null
  }

  return token
}

function _checkTokenValid(token: TokenResponse | null): boolean {
  if (!token?.expires_at) return false
  return new Date() < new Date(token.expires_at)
}
