
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'logo',

  props: {
    isDarken: { type: Boolean, default: false },
    size: {
      type: String as PropType<'big' | 'medium'>,
      default: 'medium',
    },
  },
})
