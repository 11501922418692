
import AppNav from './components/AppNav.vue'
import Logo from './components/Logo.vue'
import AppFooter from './components/AppFooter.vue'

import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { AppFooter, AppNav, Logo },

  setup() {
    const { getters } = useStore()

    return {
      isLoggedIn: computed(() => getters['IS_LOGGED_IN']),
    }
  },
})
