import _pickBy from 'lodash/pickBy'
import _mapKeys from 'lodash/mapKeys'

export const CONFIG = {
  API_URL: '',

  TERMS_OF_USE_LINK: 'https://limitlesscapital.io/termsofuse',

  PRIVACY_POLICY_LINK: 'https://limitlesscapital.io/privacypolicy',
}

declare const document: Document & { ENV: { [k: string]: unknown } }
declare const window: Document & { ENV: { [k: string]: unknown } }
declare const global: Document & { ENV: { [k: string]: unknown } }

const pickEnvConfigKeys = (source: { [k: string]: unknown }) => {
  return _mapKeys(
    _pickBy(source, (v, k) => k.startsWith('VUE_APP_')),
    (v, k) => k.replace(/^VUE_APP_/, '')
  )
}

Object.assign(
  CONFIG,
  pickEnvConfigKeys(process.env),
  pickEnvConfigKeys(document.ENV || window.ENV || global.ENV)
)
