import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d29bab4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-nav bg-nav flex items-center" }
const _hoisted_2 = { class: "app-nav__body w-full flex justify-between app-wrapper" }
const _hoisted_3 = { class: "app-nav__main-group grid grid-flow-col justify-between gap-8 items-center" }
const _hoisted_4 = { class: "grid grid-flow-col justify-center gap-4 items-center ml-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BurgerButton = _resolveComponent("BurgerButton")!
  const _component_Passport = _resolveComponent("Passport")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Logo, { class: "mr-6" }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "app-nav__item",
          to: "/balances"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('balances-link')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "app-nav__item",
          to: "/deposits"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('deposits-link')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "app-nav__item",
          to: "/withdrawals"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('withdrawals-link')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BurgerButton, {
          "is-open": _ctx.isSidebarOpen,
          "onUpdate:is-open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSidebarOpen) = $event)),
          class: "app-nav__burger"
        }, null, 8, ["is-open"]),
        _createVNode(_component_router_link, {
          class: "app-nav__item",
          to: "/change-password"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('change-password-link')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Passport, { class: "app-nav__passport" })
      ])
    ]),
    _createVNode(_component_Sidebar, {
      class: "app-nav__sidebar",
      "is-shown": _ctx.isSidebarOpen,
      "onUpdate:is-shown": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isSidebarOpen) = $event))
    }, null, 8, ["is-shown"])
  ]))
}