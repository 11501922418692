<template>
  <div class="passport relative flex items-center">
    <button @click="isDropdownOpen = !isDropdownOpen" class="flex items-center">
      <div
        class="passport__icon-wrap flex items-center justify-center rounded-full bg-passport-icon"
      >
        <img
          class="passport__icon"
          src="./../assets/passport.png"
          alt="passport"
        />
      </div>
      <ArrowIcon class="passport__arrow-icon ml-4" />
    </button>
    <transition name="passport__dropdown">
      <div
        v-if="isDropdownOpen"
        class="passport__dropdown bg-passport-dropdown flex flex-col"
      >
        <button class="passport__button text-white" @click="logOut">
          {{ t('log-out-btn') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import ArrowIcon from './ArrowIcon.vue'

import { ref, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { ArrowIcon },

  setup() {
    const isDropdownOpen = ref(false)
    const { dispatch } = useStore()
    const { push } = useRouter()

    return {
      isDropdownOpen,
      t: useI18n().t,
      logOut: () => {
        isDropdownOpen.value = false
        dispatch('LOG_OUT')
        push({ name: 'Redirector' })
      },
    }
  },
})
</script>

<style lang="scss">
.passport__dropdown {
  @apply absolute;
  @apply z-10;
  @apply right-0;

  top: calc(100% + 1.8rem);
  padding: 0.75rem 1rem;
}

.passport__icon-wrap {
  width: 2.75rem;
  height: 2.75rem;
}

.passport__icon {
  @apply text-white;
}

.passport__arrow-icon {
  width: 1.1rem;
  min-width: 1.1rem;
  height: 0.5rem;

  @apply text-white;
}

.passport__button {
  white-space: nowrap;
}

.passport__dropdown-enter-active {
  animation: passport-dropdown-keyframes 0.2s ease-in-out;
  animation-duration: 0.2s;
}

.passport__dropdown-leave-active {
  animation: passport-dropdown-keyframes 0.2s ease-in-out reverse;
  animation-duration: 0.2s;
}

@keyframes passport-dropdown-keyframes {
  from {
    opacity: 0;
    margin-top: -1.2rem;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}
</style>

<i18n locale="en-US">
{
  "log-out-btn": "Log out",
}
</i18n>
