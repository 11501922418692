export default {
  "api-errors": {
    "session-expired-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired"])},
    "session-expired-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login again to continue using the application."])},
    "unexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected error occurred"])}
  },
  "user-roles": {
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])}
  },
  "order-sides": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])}
  },
  "validators": {
    "required-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "email-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should contain a valid email"])},
    "should-match-err": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This filed should contain the ", _interpolate(_named("expected"))])}
  }
}