
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { CONFIG } from '@/config'

export default defineComponent({
  setup() {
    return {
      t: useI18n().t,
      CONFIG,
    }
  },
})
