
import Logo from './Logo.vue'
import Passport from './Passport.vue'
import BurgerButton from './BurgerButton.vue'
import Sidebar from './Sidebar.vue'

import { ref, computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { Logo, Passport, BurgerButton, Sidebar },

  setup() {
    const { getters } = useStore()
    const isSidebarOpen = ref(false)

    return {
      t: useI18n().t,
      isAdmin: computed(() => getters['USER_ROLE'] === 'admin'),
      isSidebarOpen,
    }
  },
})
