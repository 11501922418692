import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66db0e6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-footer bg-nav" }
const _hoisted_2 = { class: "app-footer__body app-wrapper flex text-white" }
const _hoisted_3 = { class: "app-footer__all-rights text-base text-center font-bold" }
const _hoisted_4 = { class: "app-footer__links flex items-center flex-wrap justify-between w-96" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('all-rights')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          class: "app-footer__link text-white font-bold text-base",
          href: _ctx.CONFIG.TERMS_OF_USE_LINK,
          target: "_blank",
          rel: "noopener"
        }, _toDisplayString(_ctx.t('terms-of-use-link')), 9, _hoisted_5),
        _createElementVNode("a", {
          class: "app-footer__link text-white font-bold text-base",
          href: _ctx.CONFIG.PRIVACY_POLICY_LINK,
          target: "_blank",
          rel: "noopener"
        }, _toDisplayString(_ctx.t('privacy-policy-link')), 9, _hoisted_6)
      ])
    ])
  ]))
}