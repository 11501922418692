import { createI18n } from 'vue-i18n'
import en from '@/locales/en-US.json'

// Docs:
// vue3 docs: https://vue-i18n.intlify.dev/
//
// Why explicit instead of injecting global $t:
// https://vue-i18n.intlify.dev/guide/advanced/composition.html#explicit-with-usei18n
//
// SFC <i18n> example:
// https://vue-i18n.intlify.dev/guide/advanced/composition.html#local-scope
//
// Locale change:
// https://vue-i18n.intlify.dev/guide/advanced/composition.html#locale-changing
//
// For vscode I recommend using "i18n ally" plugin:
// https://marketplace.visualstudio.com/items?itemName=Lokalise.i18n-ally
//
// Optimization:
// https://vue-i18n.intlify.dev/guide/advanced/optimization.html

const i18n = createI18n({
  legacy: false,
  locale: 'en-US',
  fallbackLocale: 'en-US',
  fallbackWarn: false,
  missingWarn: false,
  messages: { 'en-US': en },
})

const t = i18n.global.t

export { t }
export default i18n
