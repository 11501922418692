import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from './../assets/logo-darken.png'
import _imports_1 from './../assets/logo.png'


const _withScopeId = n => (_pushScopeId("data-v-63ef59f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "logo__img",
  src: _imports_0,
  alt: "Logo"
}
const _hoisted_2 = {
  key: 1,
  class: "logo__img",
  src: _imports_1,
  alt: "Logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["logo", [`logo--${_ctx.size}`]]),
    rel: "noopener",
    target: "_blank",
    href: "https://limitlesscapital.io/"
  }, [
    (_ctx.isDarken)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2))
  ], 2))
}