import { UserRole } from '@/api/apiEnums'
import store from '@/store'
import {
  NavigationGuardWithThis,
  RouteLocation,
  RouteLocationRaw,
} from 'vue-router'

type RouteGuard = NavigationGuardWithThis<undefined>
type RouteRedirector = (to: RouteLocation) => RouteLocationRaw

export function makeRootRedirector(
  userRoute: RouteLocationRaw,
  guestRoute: RouteLocationRaw
): RouteRedirector {
  return () => {
    if (store.getters['IS_LOGGED_IN']) {
      return userRoute
    } else {
      return guestRoute
    }
  }
}

export function makeAuthorizationGuard(
  allowedState: boolean,
  fallback: RouteLocationRaw
): RouteGuard {
  return () => {
    if (store.getters['IS_LOGGED_IN'] === allowedState) {
      return true
    }
    return fallback
  }
}

export function makeRoleGuard(
  allowedRole: UserRole,
  fallback: RouteLocationRaw
): RouteGuard {
  return () => {
    if (store.getters['USER_ROLE'] === allowedRole) {
      return true
    }
    return fallback
  }
}
