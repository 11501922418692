import { UserRole } from '@/api/apiEnums'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import {
  makeAuthorizationGuard,
  makeRootRedirector,
  makeRoleGuard,
} from './guards'

const rootRedirector = makeRootRedirector({ name: 'Balances' }, { name: 'Login' })
const usersOnly = makeAuthorizationGuard(true, { name: 'Login' })
const guestsOnly = makeAuthorizationGuard(false, { name: 'Redirector' })
const adminsOnly = makeRoleGuard(UserRole.admin, { name: 'Redirector' })

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'Redirector', redirect: rootRedirector },
  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: rootRedirector },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: guestsOnly,
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    beforeEnter: usersOnly,
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ '../views/ChangePassword.vue'
      ),
  },
  {
    path: '/balances',
    name: 'Balances',
    beforeEnter: usersOnly,
    component: () =>
      import(/* webpackChunkName: "create-account" */ '../views/Balances'),
  },
  {
    path: '/deposits',
    name: 'Deposits',
    beforeEnter: usersOnly,
    component: () =>
      import(/* webpackChunkName: "create-account" */ '../views/Deposits'),
  },
  {
    path: '/withdrawals',
    name: 'Withdrawals',
    beforeEnter: usersOnly,
    component: () =>
      import(/* webpackChunkName: "create-account" */ '../views/Withdrawals'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
