<template>
  <transition name="sidebar-transition">
    <div class="sidebar" v-if="isShown">
      <div
        class="sidebar__backdrop"
        @click="closeByClickOutside ? closeSelf() : ''"
      />
      <div class="sidebar__pane bg-nav">
        <router-link class="sidebar__item" to="/balances" @click="closeSelf">
          {{ t('balances-link') }}
        </router-link>
        <router-link class="sidebar__item" to="/deposits" @click="closeSelf">
          {{ t('deposits-link') }}
        </router-link>
        <router-link class="sidebar__item" to="/withdrawals" @click="closeSelf">
          {{ t('withdrawals-link') }}
        </router-link>
        
        <router-link
          class="sidebar__item"
          to="/change-password"
          @click="closeSelf"
        >
          {{ t('change-password-link') }}
        </router-link>

        <button class="sidebar__item text-white" @click="logOut">
          {{ t('log-out-btn') }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const EVENTS = {
  updateIsShown: 'update:isShown',
}

export default defineComponent({
  props: {
    isShown: { type: Boolean, default: true },
    closeByClickOutside: { type: Boolean, default: true },
  },

  emits: Object.values(EVENTS),

  setup(_, { emit }) {
    const { push } = useRouter()
    const { getters, dispatch } = useStore()
    const closeSelf = () => emit(EVENTS.updateIsShown, false)

    return {
      closeSelf,
      t: useI18n().t,
      isAdmin: computed(() => getters['USER_ROLE'] === 'admin'),
      logOut: () => {
        dispatch('LOG_OUT')
        push({ name: 'Redirector' })
        closeSelf()
      },
    }
  },
})
</script>

<style lang="scss">
$z-sidebar: 11;
$z-backdrop: -1;
$sidebar-padding: 2.6875rem;

.sidebar {
  position: fixed;
  width: 100vw;
  min-height: calc(100vh - 5rem);
  top: 5rem;
  left: 0;
  z-index: 11;
  overflow: hidden;
}

.sidebar__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $z-backdrop;
}

.sidebar__pane {
  position: absolute;
  top: 0;
  right: 0;
  width: 23.4375rem;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 $sidebar-padding 1rem;
}

.sidebar__item {
  @apply text-white;
  @apply whitespace-nowrap;
  @apply max-w-min;

  &.router-link-exact-active {
    @apply font-bold;
    @apply relative;

    &:before {
      content: '';

      @apply block;
      @apply absolute;
      @apply bg-white;
      left: 50%;
      bottom: -0.5rem;
      width: 80%;
      height: 0.2rem;
      transform: translateX(-50%);
    }
  }

  & + & {
    margin-top: 1.5rem;
  }
}

.sidebar-transition-enter-active {
  animation-duration: 0.13s;

  /*
    Disabled because with this nesting we can shiny see where this animation
    used
  */
  /* stylelint-disable selector-nested-pattern */
  & > .sidebar__backdrop {
    animation: sidebar-backdrop-keyframes 0.2s ease-in-out;
  }

  & > .sidebar__pane {
    animation: sidebar-pane-keyframes 0.2s ease-in-out;
  }
  /* stylelint-enable selector-nested-pattern */
}

.sidebar-transition-leave-active {
  /*
    overall duration should be less than nested durations,
    to prevent animation flickering after animation ended
    but the element still present
  */
  animation-duration: 0.13s;

  /*
    Disabled because with this nesting we can shiny see where this animation
    used
  */
  /* stylelint-disable selector-nested-pattern */
  & > .sidebar__backdrop {
    animation: sidebar-backdrop-keyframes 0.2s ease-in-out reverse;
  }

  & > .sidebar__pane {
    animation: sidebar-pane-keyframes 0.2s ease-in-out reverse;
  }
  /* stylelint-enable selector-nested-pattern */
}

@keyframes sidebar-backdrop-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes sidebar-pane-keyframes {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

<i18n locale="en-US">
{
  "balances-link": "Balances",
  "trades-link": "Trades",
  "deposits-link": "Deposits",
  "withdrawals-link": "Withdrawals",
  "users-link": "Users",
  "create-account-link": "Create Account",
  "change-password-link": "Change Password",
  "log-out-btn": "Log out",
}
</i18n>
