export enum UserRole {
  general = 'general',
  admin = 'admin',
}
export enum OrderSide {
  buy = 'buy',
  sell = 'sell',
}
// TODO: order statuses unknown
export enum OrderStatus {
  pending = 'pending',
}
