import { createStore } from 'vuex'
import { TokenResponse, UserResponse } from '@/api/apiTypes'
import { clearToken, getTokenOwner, storeToken } from '@/helpers/token'

export default createStore({
  state: {
    user: getTokenOwner() as UserResponse | null,
  },
  getters: {
    IS_LOGGED_IN: (state) => state.user !== null,
    USER_EMAIL: (state) => state?.user?.email,
    USER_ROLE: (state) => state?.user?.role,
  },
  mutations: {
    SET_USER: (state, value: UserResponse) => (state.user = value),
  },
  actions: {
    LOG_IN: ({ commit }, payload: { token: TokenResponse }) => {
      storeToken(payload.token)
      commit('SET_USER', payload.token.owner)
    },
    LOG_OUT: ({ commit }) => {
      clearToken()
      commit('SET_USER', null)
    },
  },
  modules: {},
})
