import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "app-wrapper w-full"
}
const _hoisted_2 = { class: "mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNav = _resolveComponent("AppNav")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_AppNav, {
          key: 0,
          class: "mb-14"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Logo, {
            class: "app__logo",
            "is-darken": "",
            size: "big"
          })
        ])),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_AppFooter, { class: "mt-auto" })
  ], 64))
}